
import { computed, defineComponent, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import lessVariable from "@/less/variables.less";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import { useQuery } from "@vue/apollo-composable";
import { poolStatisticsGql } from "@/api/pools/poolStats";
import { PoolStatistics } from "@/api/pools/__generated__/PoolStatistics";
import { useGetPoolList } from "@/shared/composables/api/useGetPoolList";
import random from "lodash/random";
import AmountText from "@/shared/components/typography/AmountText.vue";
import Table from "@/shared/components/lists/Table.vue";
import { convertAmountToAda } from "@/shared/utils/numbers";
import { formatPoolLabel } from "@/shared/utils/stringHelpers";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const router = useRouter();
    const poolStatistics = useQuery<PoolStatistics>(poolStatisticsGql, {});

    const { mappedDataForDisplay, showMoreIsLoading, showMore, isLoading } =
      useGetPoolList();

    const gotoPoolDetails = (hash, openOnNewTab = false) => {
      const routeData = router.resolve({
        name: routeNames.poolDetails,
        params: { hash },
      });

      if (openOnNewTab) {
        window.open(routeData.href, "_blank");
        return;
      }

      router.push(routeData);
    };

    // Expose this as global function so it can be
    // accessed via [onauxclick] for middle click
    (window as any).gotoPoolDetails = gotoPoolDetails;
    onUnmounted(() => {
      (window as any).gotoPoolDetails = null;
    });

    const tableItemMobileLabel = {
      blocks: t("Blocks"),
      epochWithBlocks: t("Epoch with blocks"),
      rewards: t("Rewards"),
      delegators: t("Delegators"),
    };

    const columns = [
      {
        title: t("Pool"),
        dataIndex: "pool",
        slots: { customRender: "pool" },
      },
      {
        title: t("Live Stake"),
        dataIndex: "profit",
        className: "pool-list__align-middle",
        slots: { customRender: "profit" },
      },
      {
        title: t("Active Stake"),
        dataIndex: "activeStake",
        slots: { customRender: "activeStake" },
      },
      {
        title: t("Delegators"),
        dataIndex: "delegators",
        className: "pool-list__align-middle",
        slots: { customRender: "defaultNumberDisplay" },
      },
      {
        title: t("Rewards"),
        dataIndex: "rewards",
        className: "pool-list__align-middle",
        slots: { customRender: "defaultNumberDisplay" },
        unit: "₳",
      },
      {
        title: t("Epoch with blocks"),
        dataIndex: "epochWithBlocks",
        className: "pool-list__align-middle",
        slots: { customRender: "defaultNumberDisplay" },
      },
      {
        title: t("Blocks"),
        dataIndex: "blocks",
        className: "pool-list__align-middle",
        slots: { customRender: "defaultNumberDisplay" },
      },
    ];

    // TODO: Update colors as needed
    const randomAvatarColor = () => {
      const avatarColors = [
        {
          color: "#0033AD",
          backgroundColor: "#FFB834",
        },
        {
          color: "#0033AD",
          backgroundColor: "#00BFD9",
        },
        {
          color: "#0033AD",
          backgroundColor: "#27AE60",
        },
      ];
      const randomIndex = random(0, 2);
      return avatarColors[randomIndex];
    };

    const tableDataSource = computed(() => {
      return mappedDataForDisplay.value.map((item) => {
        return {
          id: item?.id,
          hash: item?.hash,
          pool: {
            name: formatPoolLabel(item?.name, item?.ticker, item?.hash),
            avatar: item?.ticker ? item?.ticker.slice(0, 2) : "",
          },
          profit: {
            profitValue: item?.effectiveInterestRatePerYear * 100,
            liveValue: item?.liveStakeSaturationPercentage * 100,
          },
          activeStake: {
            amount: convertAmountToAda(item?.activeStakeAmount),
            percent: item?.liveStakeSaturationPercentage * 100,
          },
          rewards: convertAmountToAda(item?.delegatorRewardsAmount),
          delegators: item?.delegatorsCount,
          epochWithBlocks: item?.epochsWithBlocksCount,
          blocks: item?.blocksCount,
        };
      });
    });

    return {
      tableDataSource,
      columns,
      lessVariable,
      t,
      poolStatistics,
      randomAvatarColor,
      gotoPoolDetails,
      showMoreIsLoading,
      showMore,
      isLoading,
      tableItemMobileLabel,
    };
  },
  components: {
    Table,
    AmountText,
  },
});
