
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import ShowMoreButton from "@/shared/components/buttons/ShowMoreButton.vue";

export default defineComponent({
  inheritAttrs: false,
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
  props: {
    loadMore: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
    },
    isShowMoreLoading: {
      type: Boolean,
    },
    dataSource: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ShowMoreButton,
  },
});
