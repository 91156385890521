import gql from "graphql-tag";

export const poolsGql = gql`
  query Pools($filters: PoolFiltersInput) {
    pools(filters: $filters) {
      ... on PoolResults {
        results {
          id
          hash
          name
          ticker
          effectiveInterestRatePerYear
          liveStakeSaturationPercentage
          activeStakeAmount
          delegatorsCount
          delegatorRewardsAmount
          epochsWithBlocksCount
          blocksCount
        }
      }

      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
