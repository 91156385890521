import { computed, ref, toRaw, watch } from "vue";
import { useQuery } from "@vue/apollo-composable";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { poolsGql } from "@/api/pools/poolList";
import {
  Pools,
  Pools_pools_PoolResults,
  Pools_pools_PoolResults_results,
  PoolsVariables,
} from "@/api/pools/__generated__/Pools";
import { useFetchMoreListMerge } from "@/shared/composables/useMergeList";

// TODO: Add additional filters as needed if a screen has the need to support custom filters
export const useGetPoolList = () => {
  const mergedList = ref<(Pools_pools_PoolResults_results | null)[]>([]);
  const { fetchMoreListMerge } = useFetchMoreListMerge();
  const showMoreButtonTriggered = ref(false);
  const apiResponseList = useQuery<Pools, PoolsVariables>(poolsGql, {
    filters: {
      pagination: {
        offset: 0,
      },
    },
  });

  const parsedGqlBlockList = computed(() =>
    parseGqlResponse<Pools_pools_PoolResults>(
      "PoolResults",
      apiResponseList?.result?.value
    )
  );

  watch(
    parsedGqlBlockList,
    (newParsedGqlBlockList) => {
      if (
        newParsedGqlBlockList.data?.results &&
        newParsedGqlBlockList.data?.results.length > 1
      ) {
        const newMergedList =
          fetchMoreListMerge<Pools_pools_PoolResults_results | null>(
            toRaw(mergedList.value),
            newParsedGqlBlockList.data?.results ?? [],
            "id",
            showMoreButtonTriggered.value
          );

        mergedList.value = newMergedList;
        showMoreButtonTriggered.value = false;
      }
    },
    {
      immediate: true,
    }
  );

  const showMore = () => {
    showMoreButtonTriggered.value = true;
    apiResponseList.refetch({
      filters: {
        pagination: {
          offset: mergedList.value.length,
        },
      },
    });
  };

  return {
    mappedDataForDisplay: mergedList,
    showMore,
    showMoreIsLoading: showMoreButtonTriggered,
    isLoading: apiResponseList?.loading,
  };
};
