
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import BANCard from "@/shared/components/cards/BANCard.vue";
import GhostListHeader from "@/shared/components/layouts/GhostListHeader.vue";
import PageHeaderTitle from "@/shared/components/typography/PageHeaderTitle.vue";
import lessVariable from "@/less/variables.less";
import { useQuery } from "@vue/apollo-composable";
import { poolStatisticsGql } from "@/api/pools/poolStats";
import {
  PoolStatistics,
  PoolStatistics_poolStatistics_PoolStatistics,
} from "@/api/pools/__generated__/PoolStatistics";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import PoolListTable from "@/shared/components/lists/PoolListTable.vue";
import { convertAmountToAda } from "@/shared/utils/numbers";
import GlobalSearchInput from "@/shared/components/forms/GlobalSearchInput.vue";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const poolStatistics = useQuery<PoolStatistics>(poolStatisticsGql, {});
    const parsedGqlPoolStatistics = computed(() =>
      parseGqlResponse<PoolStatistics_poolStatistics_PoolStatistics>(
        "PoolStatistics",
        poolStatistics?.result?.value
      )
    );

    const cardDetails = computed(() => {
      return [
        {
          cardTitle: t("total pools"),
          cardAmount: parsedGqlPoolStatistics?.value?.data?.poolsCount,
          unitType: "blocks",
        },
        {
          cardTitle: t("pools with stake"),
          cardAmount: parsedGqlPoolStatistics?.value?.data?.poolsWithStakeCount,
          unitType: "blocks",
        },
        {
          cardTitle: t("total stake"),
          cardAmount: convertAmountToAda(
            parsedGqlPoolStatistics?.value?.data?.stakeAmount
          ),
        },
        {
          cardTitle: t("pools with blocks"),
          cardAmount:
            parsedGqlPoolStatistics?.value?.data?.poolsWithBlocksCount,
          unitType: "blocks",
        },
        {
          cardTitle: t("total delegators"),
          cardAmount: parsedGqlPoolStatistics?.value?.data?.delegatorsCount,
          unitType: "blocks",
        },
        {
          cardTitle: t("total rewards"),
          cardAmount: convertAmountToAda(
            parsedGqlPoolStatistics?.value?.data?.rewardsAmount
          ),
        },
      ];
    });

    return {
      lessVariable,
      t,
      poolStatistics,
      cardDetails,
    };
  },
  components: {
    BANCard,
    GhostListHeader,
    PageHeaderTitle,
    PoolListTable,
    GlobalSearchInput,
  },
});
