import gql from "graphql-tag";

export const poolStatisticsGql = gql`
  query PoolStatistics {
    poolStatistics {
      ... on PoolStatistics {
        activePoolsCount
        poolsCount
        poolsWithStakeCount
        poolsWithBlocksCount
        delegatorsCount
        stakeAmount
        rewardsAmount
      }

      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
