import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ca6890a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-center mt-32"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_ShowMoreButton = _resolveComponent("ShowMoreButton")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_table, _mergeProps({
      class: "ant-table-striped",
      "data-source": _ctx.dataSource,
      rowClassName: 
        (record, index) => (index % 2 === 1 ? null : 'table-striped')
      ,
      "row-key": (record, index) => record?.id ?? index,
      pagination: false,
      loading: {
        spinning: _ctx.isLoading || _ctx.dataSource == undefined,
        size: 'large',
        delayTime: 500,
        tip: _ctx.t('Loading ...'),
      }
    }, _ctx.$attrs), _createSlots({ _: 2 }, [
      _renderList(_ctx.$slots, (_, slot) => {
        return {
          name: slot,
          fn: _withCtx((scope) => [
            _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope)), undefined, true)
          ])
        }
      })
    ]), 1040, ["data-source", "rowClassName", "row-key", "loading"]),
    (_ctx.dataSource.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_ShowMoreButton, {
            onClick: _ctx.loadMore,
            loading: _ctx.isShowMoreLoading
          }, null, 8, ["onClick", "loading"])
        ]))
      : _createCommentVNode("", true)
  ]))
}