import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "pool-list w-100" }
const _hoisted_2 = { class: "w-100 mt-30 mb-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GlobalSearchInput = _resolveComponent("GlobalSearchInput")!
  const _component_PageHeaderTitle = _resolveComponent("PageHeaderTitle")!
  const _component_BANCard = _resolveComponent("BANCard")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_GhostListHeader = _resolveComponent("GhostListHeader")!
  const _component_PoolListTable = _resolveComponent("PoolListTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_GlobalSearchInput),
    _createVNode(_component_PageHeaderTitle, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Pools")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, {
      justify: "center",
      align: "middle",
      wrap: "",
      gutter: { lg: 16 }
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cardDetails, (item) => {
          return (_openBlock(), _createBlock(_component_a_col, {
            lg: 8,
            xs: 24,
            key: item
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BANCard, {
                class: "mb-20",
                cardDetails: item,
                loading: _ctx.poolStatistics?.loading?.value,
                "unit-type": item?.unitType
              }, null, 8, ["cardDetails", "loading", "unit-type"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_GhostListHeader, null, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.t("Top list")), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_a_row, { class: "ant-row ant-row-center" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_PoolListTable)
        ])
      ]),
      _: 1
    })
  ]))
}